import { request } from '.'

function createAccount(data) {
  return request({
    type: 'POST',
    action: `profiles/create_simple/`,
    microservice: 'profiles',
    data,
  })
}

function blockAccount() {
  return request({
    type: 'POST',
    action: `/checkoutblock`,
    microservice: 'segmentation',
    auth: 'token',
  })
}

function checkEmailExists(email) {
  return request({
    type: 'GET',
    action: `check_email/?email=${email}`,
    microservice: 'profiles',
  })
}

function postReports(company, profile) {
  return request({
    type: 'POST',
    action: `newcheckoutuser`,
    microservice: 'reports',
    auth: 'token',
    data: {
      company_name: `${company.name} (${company.id})`,
      user_email: profile.email,
      user_phone: company.cellphone,
    },
  })
}

function login(username, password) {
  return request({
    type: 'POST',
    action: 'platform/login/',
    data: { username: username, password: password },
  })
}

export const requester = {
  createAccount,
  blockAccount,
  checkEmailExists,
  postReports,
  login,
}
