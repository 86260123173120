import React, { useRef, useState } from 'react'
import {
  Row,
  Col,
  Grid,
  Form,
  SelectPicker,
  // Checkbox,
  // CheckboxGroup,
  MaskedInput,
  InputGroup,
  Button,
} from 'rsuite'

import { requester } from '../../data/requester'
import Swal from 'sweetalert2'
import logo from '../../assets/new_logo_white.png'
import { submit_hubspot_form } from '../../data/hubspot'
import schema from './validators'
import { useHistory } from 'react-router-dom'

const CreateAccountForm = () => {
  const formRef = useRef()
  const { push } = useHistory()

  const [formError, setFormError] = useState({})
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
    password: generatePassword(16),
    cellphone: null,
    company_employees: [],
    company_name: '',
    law_area: [],
    agreement: [],
    cupom: [],
    phone_validation_value: 'X',
    phone_validation_id: 'dd0a5f48-17e3-4a56-b9fa-3ec33b8a45df',
  })

  const options = [
    {
      label: 'Sou estudante',
      value: 'Estudante',
      role: 'Estudante',
    },
    {
      label: 'Sou advogado autônomo',
      value: 'Sou advogado autônomo',
      role: 'Sou advogado autônomo',
    },
    {
      label: 'De 2 a 5 advogados',
      value: 'De 2 a 5 advogados',
      role: 'De 2 a 5 advogados',
    },
    {
      label: 'De 6 a 20 advogados',
      value: 'De 6 a 20 advogados',
      role: 'De 6 a 20 advogados',
    },
    {
      label: 'De 21 a 50 advogados',
      value: 'De 21 a 50 advogados',
      role: 'De 21 a 50 advogados',
    },
    {
      label: '51 ou mais advogados',
      value: '51 ou mais advogados',
      role: '51 ou mais advogados',
    },
    {
      label: 'Atuo em Departamento Jurídico',
      value: 'Departamento Jurídico',
      role: 'Departamento Jurídico',
    },
    {
      label: 'Atuo em Órgão Público',
      value: 'Atuo em Órgão Público',
      role: 'Atuo em Órgão Público',
    },
  ]

  const [loading, setLoading] = useState(false)
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false)

  const [openCellphoneModal, setOpenCellphoneModal] = useState(false)
  const [cellphoneVerificationId, setCellphoneVerificationId] = useState('')
  const [cellphoneVerificationCode, setCellphoneVerificationCode] = useState('')
  const [cellphoneVerificationError, setCellphoneVerificationError] =
    useState(null)

  const handleCancelSubmit = () => {
    setOpenCellphoneModal(false)
    setLoadingCreateAccount(false)
    setLoading(false)
  }

  const handleCreateAccount = async () => {
    setLoadingCreateAccount(true)
    try {
      setCellphoneVerificationError(null)

      let requestData = {
        ...formValue,
      }
      requestData.agreement = requestData.agreement.reduce(
        (acc, value) => ({ ...acc, [value]: true }),
        {}
      )

      const { company, profile } = await createAccount(requestData)
      setOpenCellphoneModal(false)

      postToHubspot(company, profile, requestData.company_employees)

      requester.blockAccount()

      linkedingTrack()

      window.lintrk('track', { conversion_id: 11086026 })

      const resultPopUp = await Swal.fire({
        text: 'Conta criada com sucesso!',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#15c16b',
      })
      if (resultPopUp.isConfirmed) {
        window.location.href = 'https://app.turivius.com/trial'
      }
    } catch (error) {
      setLoading(false)
      setLoadingCreateAccount(false)
      if (error?.message === 'Invalid token') {
        setCellphoneVerificationError('Código de verificação inválido')
      } else if (error?.message === 'Validation token expired') {
        handleCancelSubmit()
        Swal.fire({
          text: 'Seu código de verificação expirou. Tente criar a conta novamente.',
          icon: 'error',
          showCancelButton: true,
          cancelButtonColor: '#ff6961',
          showConfirmButton: false,
        })
      } else if (
        error?.message ===
        'Could not complete profile creation because there is already a user with this e-mail.'
      ) {
        handleCancelSubmit()
        Swal.fire({
          text: 'Encontramos uma conta com este e-mail. Tente fazer login em https://app.turivius.com',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Acessar a Turivius',
          confirmButtonColor: '#15c16b',
          showCancelButton: true,
          cancelButtonText: 'Fechar',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = 'https://app.turivius.com'
          }
        })
      } else {
        push('/trial')
      }
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!formRef.current.check()) {
        setLoading(false)
        setStep(1)
        return
      }

      setCellphoneVerificationId(cellphoneVerificationId)
      await handleCreateAccount()
    } catch (error) {
      setLoading(false)
      setStep(1)
      Swal.fire({
        title: 'Erro',
        text: 'Verifique os campos e tente novamente.',
        icon: 'error',
        cancelButtonText: 'Fechar',
        confirmButtonColor: '#ff6961',
      })
    }
  }

  const goToApp = () => {
    window.location.href = `${process.env.REACT_APP_URL}`
  }

  const createAccount = async (data) => {
    return await requester.createAccount(data)
  }

  const checkCellphone = (cellphone) => {
    if (cellphone && cellphone[9] !== '_' && cellphone.slice(-1) === '_') {
      Swal.fire({
        text: 'Preencha um número válido',
        icon: 'error',
        showCancelButton: true,
        cancelButtonColor: '#ff6961',
        showConfirmButton: false,
      })
    }
    return true
  }

  const [step, setStep] = useState(1)

  const postToHubspot = (company, profile, company_employees) => {
    submit_hubspot_form(company, profile, company_employees)
  }

  const linkedingTrack = () => {
    let img = document.createElement('img')
    img.setAttribute(
      'src',
      'https://px.ads.linkedin.com/collect/?pid=1948522&conversionId=4291570&fmt=gif'
    )
    img.setAttribute('width', '1px')
    img.setAttribute('height', '1px')
    img.setAttribute('style', 'display:none;')
    img.setAttribute('alt', '')
    document.body.appendChild(img)
  }

  const login = async (username, password) => {
    return await requester.login(username, password)
  }

  function generatePassword(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_@!?'

    let password = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      )
    }
    return password
  }

  return (
    <div className='account-form'>
      <a href='https://turivius.com/' data-testid='logo'>
        <img src={logo} alt='Logo da Turivius' className='logo-turivius' />
      </a>
      <div>
        <h1 className='account-title'>
          <b>Gestão Jurisprudencial e Jurimetria com IA</b>
        </h1>
        <h3 className='account-subtitle'>
          Preencha seus dados e um de nossos especialistas entrará em contato
          para uma demonstração personalizada para seu escritório/departamento:
        </h3>
        <Form
          className='create-form'
          fluid
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          formValue={formValue}
          model={schema}
          disabled={loading}
        >
          {step === 1 && (
            <Grid fluid>
              <Row>
                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.ControlLabel className='form-label'>
                      Nome Completo*
                    </Form.ControlLabel>
                    <InputGroup>
                      <Form.Control
                        name='name'
                        className='form-input'
                        placeholder='Insira seu nome aqui'
                      />
                      <InputGroup.Addon className='form-icon'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.ControlLabel className='form-label'>
                      E-mail Corporativo*
                    </Form.ControlLabel>
                    <InputGroup>
                      <Form.Control
                        placeholder='Insira seu e-mail corporativo aqui'
                        data-testid='email-input'
                        type='email'
                        className='form-input'
                        name='email'
                      />
                      <InputGroup.Addon className='form-icon'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z'
                            stroke='#292D32'
                            strokeWidth='1.5'
                            strokeMiterlimit='10'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9'
                            stroke='#292D32'
                            strokeWidth='1.5'
                            strokeMiterlimit='10'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.ControlLabel className='form-label'>
                      WhatsApp*
                    </Form.ControlLabel>
                    <InputGroup>
                      <Form.Control
                        name='cellphone'
                        accepter={MaskedInput}
                        mask={[
                          '+',
                          '5',
                          '5',
                          ' ',
                          '(',
                          /[1-9]/,
                          /[1-9]/,
                          ')',
                          ' ',
                          /[1-9]/,
                          /[1-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        showMask
                        className='form-input'
                        onBlur={() => checkCellphone(formValue.cellphone)}
                      />
                      <InputGroup.Addon>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z'
                            stroke='#292D32'
                            strokeWidth='1.5'
                            strokeMiterlimit='10'
                          />
                        </svg>
                      </InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.Control
                      className='dropdown'
                      name='company_employees'
                      accepter={SelectPicker}
                      cleanable={false}
                      placeholder='Quantos advogados há no seu escritório?*'
                      data={options}
                      block
                      searchable={false}
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group form-areas'>
                    <Form.ControlLabel className='form-label'>
                      Em qual destas áreas seu escritório/empresa atua:*
                    </Form.ControlLabel>
                    <Form.Control
                      name='law_area'
                      accepter={CheckboxGroup}
                      className='checkbox-group'
                    >
                      <Checkbox
                        className='checkbox-option'
                        value='Trabalhista'
                        label='Trabalhista'
                      >
                        Trabalhista
                      </Checkbox>
                      <Checkbox
                        className='checkbox-option'
                        value='Tributário'
                        label='Tributário'
                      >
                        Tributário
                      </Checkbox>
                      <Checkbox
                        className='checkbox-option'
                        value='Previdenciário'
                        label='Previdenciário'
                      >
                        Previdenciário
                      </Checkbox>
                      <Checkbox
                        className='checkbox-option'
                        value='Criminal'
                        label='Criminal'
                      >
                        Criminal
                      </Checkbox>
                      <Checkbox
                        className='checkbox-option'
                        value='Cível'
                        label='Cível'
                      >
                        Cível
                      </Checkbox>
                      <Checkbox
                        className='checkbox-option'
                        value='Não sou advogado'
                        label='Não sou advogado'
                      >
                        Não sou advogado
                      </Checkbox>
                    </Form.Control>
                  </Form.Group>
                </Col> */}

                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.ControlLabel className='form-label'>
                      Nome do escritório/empresa:
                    </Form.ControlLabel>
                    <InputGroup>
                      <Form.Control
                        name='company_name'
                        className='form-input'
                        placeholder='Insira o nome aqui'
                      />
                      <InputGroup.Addon className='form-icon'></InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className='button'
                onClick={handleSubmit}
                loading={loading}
              >
                Solicitar contato
              </Button>
            </Grid>
          )}
          {step === 2 && (
            <Grid fluid>
              <Row>
                <Col xs={24} md={24} sm={24}>
                  <Form.Group className='form-group'>
                    <Form.ControlLabel className='form-label'>
                      Crie sua senha de acesso
                    </Form.ControlLabel>
                    <Form.Control
                      type='password'
                      className='form-input'
                      name='password'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className='button'
                onClick={handleSubmit}
                loading={loading}
              >
                Solicitar contato
              </Button>
            </Grid>
          )}
          <h3 className='accept'>
            Ao enviar este formulário você aceita os&nbsp;
            <a
              href='https://turivius.com/termos-de-uso'
              target='noopener noreferrer'
            >
              Termos de uso&nbsp;
            </a>
            e a&nbsp;
            <a
              href='https://turivius.com/politica-de-privacidade'
              target='noopener noreferrer'
            >
              Política de privacidade&nbsp;
            </a>
            da Turivius.
          </h3>
        </Form>
      </div>
    </div>
  )
}

export default CreateAccountForm
